.fNavbar{
    background-color: #052441;
    height:25vh;
    width: 100vw;
}

.fLogo{
    height:8vh;
    margin:2vh;
    margin-left: 3vh;
}

.LogoInfo{
    display: flex;
    width: 80vw;
    align-items: center;
    justify-content: right;

}

.fInfoEle{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2vh;
    font-size:2.6vh;
    color: #fff;

}

.Pages{
    display:flex;
    align-items: center;
    justify-content: center;
}


.Navbox{
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Spacer{
    width: 10vw;
}

.Elelogo{
    width: 4.6vh;
    margin-left: 2vh;
    margin-right: 1vh;

}

.Disclaimer{
    color:#fff;
    padding-left: 6vh;
    padding-right: 6vh;
    padding-top: 0vh;
    font-size: 2vh;
}


@media (orientation: portrait) {
    .fNavbar{
        background-color: #052441;
        height:35vh;
        width: 100vw;
    }
    
    .fLogo{
        height:8vh;
        margin:2vh;
        margin-left: 3vh;
    }
    
    .LogoInfo{
        display: flex;
        flex-direction: column;
        width: 80vw;
        align-items: center;
        justify-content: right;
    
    }
    
    .fInfoEle{
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0vw;
        font-size:2.6vw;
        color: #fff;
    
    }
    
    .Pages{
        display:flex;
        align-items: center;
        justify-content: center;
    }
    
    
    .Navbox{
        width: 100vw;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .Spacer{
        width: 0vw;
    }
    
    .Elelogo{
        width: 4.6vh;
        margin-left: 2vh;
        margin-right: 1vh;
    
    }
    
    .Disclaimer{
        color:#fff;
        padding-left: 6vw;
        padding-right: 6vw;
        padding-top: 0vh;
        font-size: 2.4vw;
    }
}