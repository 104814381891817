.embla {
  max-width: 75vw;
  margin: 1vh;
  --slide-height: 30vw; /* Increased height */
  --slide-spacing: 0vw; /* Increased spacing */
  --slide-size: 60%; /* Increased slide size */
}
.embla__viewport {
}

.embla__container {
  display: flex;
  touch-action: pan-y pinch-zoom;
  margin-left: calc(var(--slide-spacing) * -1);
}
.embla__slide {
  transform: translate3d(0, 0, 0);
  flex: 0 0 var(--slide-size);
  min-width: 0;
  padding-left: var(--slide-spacing);
}
.embla__slide__number {
  box-shadow: inset 0 0 0 0.4vh var(--detail-medium-contrast);
  border-radius: 3.6vh;
  font-size: 9vh; /* Increased font size */
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--slide-height);
  user-select: none;
}
.embla__controls {
  display: grid;
  grid-template-columns: auto 1fr;
  justify-content: space-between;
  gap: 2.4vh;
  margin-top: 3.6vh;
}
.embla__buttons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.2vh;
  align-items: center;
}
.embla__button {
  -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  box-shadow: inset 0 0 0 0.4vh var(--detail-medium-contrast);
  width: 9vh; /* Increased button size */
  height: 9vh; /* Increased button size */
  z-index: 1;
  border-radius: 50%;
  color: var(--text-body);
  display: flex;
  align-items: center;
  justify-content: center;
}
.embla__button:disabled {
  color: var(--detail-high-contrast);
}
.embla__button__svg {
  width: 50%; /* Increased SVG size */
  height: 50%; /* Increased SVG size */
}
.embla__progress {
  border-radius: 3.6vh;
  box-shadow: inset 0 0 0 0.4vh var(--detail-medium-contrast);
  background-color: gainsboro;
  position: relative;
  height: 1.6vh; /* Increased progress bar height */
  justify-self: flex-end;
  align-self: center;
  width: 30vh; /* Increased progress bar width */
  max-width: 90%;
  overflow: hidden;
}
.embla__progress__bar {
  background-color: #041b31;
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  left: -100%;
}


@media (orientation: portrait) {
  .embla {
    max-width: 75vw;
    margin: 1vh;
    --slide-height: 30vh; /* Increased height */
    --slide-spacing: 17vw; /* Increased spacing */
    --slide-size: 60%; /* Increased slide size */
  }
  .embla__viewport {
    overflow-x: hidden;
  }
  
  .embla__container {
    display: flex;
    touch-action: pan-y pinch-zoom;
    margin-left: calc(var(--slide-spacing) * -1);
  }
  .embla__slide {
    transform: translate3d(0, 0, 0);
    flex: 0 0 var(--slide-size);
    min-width: 0;
    padding-left: var(--slide-spacing);
  }
  .embla__slide__number {
    box-shadow: inset 0 0 0 0.4vh var(--detail-medium-contrast);
    border-radius: 3.6vh;
    font-size: 9vh; /* Increased font size */
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    height: var(--slide-height);
    user-select: none;
  }
  .embla__controls {
    display: grid;
    grid-template-columns: auto 1fr;
    justify-content: space-between;
    gap: 2.4vh;
    margin-top: 3.6vh;
  }
  .embla__buttons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.2vh;
    align-items: center;
  }
  .embla__button {
    -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    touch-action: manipulation;
    display: inline-flex;
    text-decoration: none;
    cursor: pointer;
    border: 0;
    padding: 0;
    margin: 0;
    box-shadow: inset 0 0 0 0.4vh var(--detail-medium-contrast);
    width: 9vh; /* Increased button size */
    height: 9vh; /* Increased button size */
    z-index: 1;
    border-radius: 50%;
    color: var(--text-body);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .embla__button:disabled {
    color: var(--detail-high-contrast);
  }
  .embla__button__svg {
    width: 50%; /* Increased SVG size */
    height: 50%; /* Increased SVG size */
  }
  .embla__progress {
    border-radius: 3.6vh;
    box-shadow: inset 0 0 0 0.4vh var(--detail-medium-contrast);
    background-color: gainsboro;
    position: relative;
    height: 1.6vh; /* Increased progress bar height */
    justify-self: flex-end;
    align-self: center;
    width: 30vh; /* Increased progress bar width */
    max-width: 100%;
    overflow: hidden;
  }

  .embla__progress__bar {
    background-color: #041b31;
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    left: -100%;
  }
  
}