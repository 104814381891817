body{
    overflow-x: hidden;
}

.Pagecontent{
    width: 100vw;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    color: #f0ebd8;
    background: linear-gradient(to top, #e9ecef, #f8f9fa);
    /* background: url("https://cdn.pixabay.com/video/2021/08/19/85590-590014592_tiny.jpg") */
}


.fCard{
    width: 100vw;
    display: flex;
    padding: 6vh;
    align-items: center;
    justify-content: center;
    /* background: linear-gradient(to bottom, #c6c7c9, rgba(128, 126, 126, 0.1)); */
    border-radius: 20px;
    position:relative;
    z-index: 100;
    background-color: #d7d6d6;
}

.Card{
    width: 80vw;
    display: flex;
    margin: 6vh;
    padding: 2vh;
    /* background: linear-gradient(to bottom, #c6c7c9, rgba(128, 126, 126, 0.1)); */
    border-radius: 20px;
    position:relative;
    z-index: 100;
    background-color: #d7d6d6;
}

.curve{
    position: absolute;
    height: 225px;
    width: 100%;
    bottom: 0;
    z-index: 1;
}

.curve::before{
    content: '';
    position: absolute;
    display: block;
    border-radius: 100% 50%;
    width:70%;
    height: 100%;
    background-color: #a7a7a9;
    transform: translate(62%, 50%);
}

.curve::after{
    content: '';
    position: absolute;
    display: block;
    border-radius: 100% 50%;
    width: 70%;
    height: 100%;
    background-color: #d7d6d6;
    transform: translate(-30%, 40%);
}



.Card:hover{
    /* transform: scale(1.05); */
}

.Cardimg{
    height: 40vh;
    margin: 0rem;
    border-radius: 20px;
    z-index: 100;

}

.Cardimgf{
    height:50vh;
    margin: 0rem;
    z-index: 100;
    padding-top: 3vw;

}

.Cardcontent{
    padding: 2vh;
    padding-left: 6vh;
    padding-right: 6vh;
    z-index: 100;


}


.Cardcontentcar{
    padding: 0rem;
    padding-left: 0em;
    padding-right: 0rem;
    z-index: 100;
}

.Cardtitle{
    font-size: 6vh;
    color:#001d3d;

}

.Carddescription{
    font-size: 2.6vh;
    padding: 2vh;
    color: #001d3d;
}

.Title{
    font-size: 8vh;
    margin: 2vh;
    color:#001d3d;
    align-items: left;
    z-index: 100;

}

.Modern{
    display: flex;
    width: 90vw;
    margin-top: 4vh;
    margin-bottom: 4vh;
}

.Text{
    margin-top: 4vh;
    font-size: 2.6vh;
    color: #001d3d;
}

.Location{
    font-size: 2.4vh;
    align-items: center;
    display: flex;
}

.Contactus{
    display: flex;
    width: 40vw;
    padding: 2vh;
    align-items: center;
    justify-content: space-evenly;
    color: #001d3d;
    background-color: #d7d6d6;
    border-radius: 20px;
}



.Holder{

}

.P{
    font-size: 2.2vh;
    
}

.sInfoEle{
    display: flex;
    align-items: center;
    color: #001d3d;
}

body, html {
    height: 100%;
    margin: 0;
    font-family: Arial, sans-serif;
}

.Cardholder{
    display: grid;
    width: 100vw;
    align-items: center;
    justify-content: center;

}

@media (orientation: portrait) {
    body{
        overflow-x: hidden;
    }
    
    .Pagecontent{
        width: 100vw;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        color: #f0ebd8;
        background: linear-gradient(to top, #e9ecef, #f8f9fa);
        /* background: url("https://cdn.pixabay.com/video/2021/08/19/85590-590014592_tiny.jpg") */
    }
    
    
    .fCard{
        width: 100vw;
        display: flex;
        padding: 6vh;
        align-items: center;
        justify-content: center;
        /* background: linear-gradient(to bottom, #c6c7c9, rgba(128, 126, 126, 0.1)); */
        border-radius: 20px;
        position:relative;
        z-index: 100;
        background-color: #d7d6d6;
    }
    
    .Card{
        width: 80vw;
        display: flex;
        flex-direction: column;
        margin: 6vh;
        padding: 2vh;
        /* background: linear-gradient(to bottom, #c6c7c9, rgba(128, 126, 126, 0.1)); */
        border-radius: 20px;
        position:relative;
        z-index: 100;
        background-color: #d7d6d6;
    }
    
    .curve{
        position: absolute;
        height: 225px;
        width: 100%;
        bottom: 0;
        z-index: 1;
    }
    
    .curve::before{
        content: '';
        position: absolute;
        display: block;
        border-radius: 100% 50%;
        width:70%;
        height: 100%;
        background-color: #a7a7a9;
        transform: translate(62%, 50%);
    }
    
    .curve::after{
        content: '';
        position: absolute;
        display: block;
        border-radius: 100% 50%;
        width: 70%;
        height: 100%;
        background-color: #d7d6d6;
        transform: translate(-30%, 40%);
    }
    
    
    
    .Card:hover{
        /* transform: scale(1.05); */
    }
    
    .Cardimg{

        height: 55vw;
        margin: 0rem;
        border-radius: 20px;
        z-index: 100;
    
    }
    
    .Cardimgf{
        height:50vh;
        margin: 0rem;
        z-index: 100;
        padding-top: 3vw;
    
    }
    
    .Cardcontent{
        padding: 2vw;
        padding-left: 2vw;
        padding-right: 2vw;
        z-index: 100;
    
    
    }
    
    
    .Cardcontentcar{
        padding: 0rem;
        padding-left: 0em;
        padding-right: 0rem;
        z-index: 100;
    }
    
    .Cardtitle{
        font-size: 6vh;
        color:#001d3d;
    
    }
    
    .Carddescription{
        font-size: 2.6vh;
        padding: 2vh;
        color: #001d3d;
    }
    
    .Title{
        font-size: 8vh;
        margin: 2vh;
        color:#001d3d;
        align-items: left;
        z-index: 100;
    
    }
    
    .Modern{
        display: flex;
        flex-direction: column;
        width: 100vw;
        margin-top: 4vh;
        margin-bottom: 4vh;
    }
    
    .Text{
        margin-top: 4vw;
        font-size: 3.6vw;
        color: #001d3d;
    }
    
    .Location{
        font-size: 2.4vh;
        align-items: center;
        display: flex;
    }
    
    .Contactus{
        display: flex;
        width: 100%;
        padding: 2vh;
        align-items: center;
        justify-content: space-evenly;
        color: #001d3d;
        background-color: #d7d6d6;
        border-radius: 20px;
    }
    
    
    
    .Holder{
    
    }
    
    .P{
        font-size: 2.2vh;
        
    }
    
    .sInfoEle{
        display: flex;
        align-items: center;
        color: #001d3d;
    }
    
    body, html {
        height: 100%;
        margin: 0;
        font-family: Arial, sans-serif;
    }
    
    .Cardholder{
        display: grid;
        width: 100vw;
        align-items: center;
        justify-content: center;
    
    }
}