.Navbar{
    background-color: #041b31;  
    height: 20vh; /* Adjust this to the desired border color */
    display: flex;
    width: 100vw;
}

.Logo{
    height:18vh;
    margin:2vh;
}

.LogoInfo{
    display: flex;
    flex-direction: row;
    width: 70vw;
    align-items: center;
    justify-content: right;

}

.InfoEle{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1vw;
    font-size:2.2vh;
    color: #fff;

}

.Pages{
    display:flex;
    align-items: center;
    justify-content: center;
}

.Link{
    margin-top: 2vh;
    margin-left:3vh;
    margin-right: 3vh;
    font-size: 3vh;
    color: #fff ;
    text-decoration-color: #fff;
    text-decoration: none;
    text-decoration-color: transparent;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.Link div{
    font-size: 2.8vh;
    color:#fff;
    
}

.Navbox{
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Spacer{
    width: 0vw;
}

.Elelogo{
    width: 4.6vh;
    height: 6vh;
    margin-left: 1vh;
    margin-right: 1vh;
    color: #fff;
    fill: #fff;
}

.Elelogof{
    width: 4.6vh;
    height: 6vh;
    margin-left: 1vh;
    margin-right: 1vh;
    color: #fff;
    fill: #fff;
}


.Media{
    display: flex;
    flex-direction: row;
}

svg{
    stroke:#fff;
    border-color: #fff;
}


@media (orientation: portrait) {
    .Navbar{
        background-color: #041b31;  
        height: 15vh; /* Adjust this to the desired border color */
        display: flex;
        width: 100vw;
    }
    
    .Logo{
        height:15vw;
        margin:2vw;
    }

    .Media{
        display: flex;
        flex-direction: row;
    }
    
    .LogoInfo{
        display: flex;
        width: 10vw;
        align-items: center;
        justify-content: right;
        flex-direction: column;
    
    }
    
    .InfoEle{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 1vw;
        font-size:2.2vw;
        color: #fff;
    
    }
    
    .Pages{
        display:flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 20vw;
        left:0vw;
    }
    
    .Link{
        margin-top: 2vw;
        margin-left:3vw;
        margin-right: 3vw;
        font-size: 3vw;
        color: #fff ;
        text-decoration-color: #fff;
        text-decoration: none;
        text-decoration-color: transparent;
        font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    }
    
    .Link div{
        font-size: 3vw;
        color:#fff;
        
    }
    
    .Navbox{
        width: 100vw;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .Spacer{
        width: 0vw;
    }
    
    .Elelogo{
        width: 4.6vh;
        height: 6vh;
        margin-left: 1vh;
        margin-right: 1vh;
        color: #fff;
        fill: #fff;
    }
    
    .Elelogof{
        width: 4.6vw;
        height: 6vh;
        margin-left: 1vh;
        margin-right: 1vh;
        color: #fff;
        fill: #fff;
    }

    svg{
        stroke:#fff;
        border-color: #fff;
    }
}