.fPagecontent{
    width: 100vw;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    color: #041b31;
}

.Welcome{
    width: 100vw;
    position: absolute;
    top:20vh;
    left:0vh;
    z-index: 0;
}

.section1{
    margin:6vh;
    display: flex;
    z-index: 100;
    width: 50vw;
}

.Slogan{
    z-index: 100;
}

.Slogantitle{
    font-size: 8vh;
    font-weight: 550;
    line-height:10vh;
}   

.Follow{
    margin: 8vh;
    font-size: 2.6vh;
    margin-right: 4vh;
    background-color: transparent;
    width: 25vw;
    display: flex;
        
}

.Boxed{
    display: flex;
    width: 100vw;
    height: 90vh;
}

.Services{
    position: relative;
    width: 100vw;
    height: 100vh;
    /* background-color: red; */
    margin-top: -5vh;
    display: flex;
    align-items: center;
    justify-content: center;
}



.fContactus{

    margin-left:3vw;
}

.sCard{
    height: 50vh;
    width: 30vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.sImg{
    width: 30vw;
    position: absolute;
    z-index: 1;
}

.sCardtitle{
    z-index: 100;
    font-size: 6vh;
}


.sCarttext{
    z-index: 100;
}

.Cardtitlecar{
    z-index: 100;
    font-size: 2vw;
    width: 19vw;


}

.Cardimgcar{
    height: 20vw;
    margin: 0rem;
    border-radius: 20px;
    z-index: 100;
}

.Cardcar{
    height: 100%;
    display: flex;
    margin: 6vh;
    /* background: linear-gradient(to bottom, #c6c7c9, rgba(128, 126, 126, 0.1)); */
    border-radius: 20px;
    position:relative;
    z-index: 100;
    background-color: #d7d6d6;
}

.Wrapper{
    display: flex;
    padding: 1vw;
}


.fTitle{
    font-size: 8vh;
    margin-top: -6vh;
    color:#001d3d;
    align-items: left;
    z-index: 100;

}

.Background{
    position: absolute;
    background-color: #fff;
    height: 100vh;
    width: 120vw;
    top: 100vh;
    border-radius: 1000px;
}

button{
    font-size: 1.2vw;
    background-color: #001d3d;
    color: #fff;
    padding: 0.9vw;
    border-radius: 20px;
    width: 5vw;
}


@media (orientation: portrait) {
    .fPagecontent{
        width: 100vw;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        color: #041b31;
    }
    
    .Welcome{
        width: 100vw;
        position: absolute;
        top:15vh;
        left:0vh;
        z-index: 0;
    }
    
    .section1{
        margin:6vw;
        display: flex;
        z-index: 100;
        width: 50vw;
    }
    
    .Slogan{
        z-index: 100;
    }
    
    .Slogantitle{
        font-size: 5vw;
        font-weight: 550;
        line-height:4.5vw;
    }   
    
    .Follow{
        margin-top: 2vw;
        margin-left: -1vw;
        font-size: 3.6vw;
        margin-right: 2vw;
        background-color: transparent;
        width: 25vw;
        display: flex;
            
    }
    
    .Boxed{
        display: flex;
        width: 100vw;
        height: 40vh;
    }
    
    .Services{
        position: relative;
        width: 100vw;
        height: 50vh;
        /* background-color: red; */
        margin-top: -5vw;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .fAboutus{
        width: 100vw;
        /* background-color: red; */
        
    }
    
    
    .fContactus{
    }
    
    .sCard{
        width: 30vw;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .sImg{
        width: 30vw;
        position: absolute;
        z-index: 1;
    }
    
    .sCardtitle{
        z-index: 100;
        font-size: 6vh;
    }
    
    
    .sCarttext{
        z-index: 100;
    }
    
    .Cardtitlecar{
        z-index: 100;
        font-size: 5vw;
        width: 35vw;
    
    
    }
    
    .Cardimgcar{
        height: 20vh;
        margin: 0rem;
        border-radius: 20px;
        z-index: 100;
    }
    
    .Cardcar{
        height: 100%;
        display: flex;
        margin: 6vw;
        /* background: linear-gradient(to bottom, #c6c7c9, rgba(128, 126, 126, 0.1)); */
        border-radius: 20px;
        position:relative;
        z-index: 100;
        background-color: #d7d6d6;
    }
    
    .Wrapper{
        display: flex;
        padding: 0.3vw;
        justify-content: center;
        align-items: center;
        margin-top: -3vw;
        margin-left: 1vw;
    }
    
    
    .fTitle{
        font-size: 8vh;
        margin-top: -2vh;
        color:#001d3d;
        align-items: left;
        z-index: 100;
    
    }
    
    .Background{
        position: absolute;
        background-color: #fff;
        height: 100vh;
        width: 120vw;
        top: 60vh;
        border-radius: 200px;
    }
    
    button{
        font-size: 3vw;
        background-color: #001d3d;
        color: #fff;
        padding: 0.2vw;
        border-radius: 20px;
        width: 10vw;
    }
    
}
